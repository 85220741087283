import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ['document', 'destroyButton']

  call() {
    const contentId = this.destroyButtonTarget.dataset.contentId
    const documentId = this.destroyButtonTarget.dataset.documentId

    fetch(`/contents/${contentId}/destroy_document?document=${documentId}`, {
      method: 'POST',
      headers: { 'Accept': "application/json", 'X-CSRF-Token': csrfToken() },
    })
      .then(response => response.json())
      .then((data) => {
        if(data.response == 'ok') this.documentTarget.remove()
      })
  }
}
