import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs"

export default class extends Controller {
  static targets = [
    'buttonAdd',
    'buttonDelete',
    'contactForm',
    'counter',
    'deleteForm',
    'updateForm',
    'clickContainer',
    'counterContainer'
  ]

  initialize() {
    this.state = {
      displayAddForm: false,
      displayUpdateForm: 0,
      displayDeleteForm: false,
      contactsToDelete: []
    }
  }

  // Global handlers
  closeForms() {
    this.state.displayAddForm && this.handleHideAddForm()
    this.state.displayUpdateForm !== 0 && this.handleHideUpdateForm(this.state.displayUpdateForm)
    this.state.displayDeleteForm && this.handleHideDeleteForm()
  }

  // Add a contact
  handleDisplayAddForm() {
    this.closeForms()
    this.buttonAddTarget.classList.add('active')
    this.contactFormTarget.hidden = false
    this.state.displayAddForm = true
  }

  handleHideAddForm() {
    this.buttonAddTarget.classList.remove('active')
    this.contactFormTarget.hidden = true
    this.state.displayAddForm = false
  }

  handleClickOnAdd() {
    this.state.displayAddForm ? this.handleHideAddForm() : this.handleDisplayAddForm()
  }

  // Update a contact
  handleDisplayUpdateForm(contactId) {
    this.closeForms()
    this.deleteFormTargets.find(el => el.dataset.id === contactId).hidden = true
    this.updateFormTargets.find(el => el.dataset.id === contactId).hidden = false
    this.state.displayUpdateForm = contactId
  }

  handleHideUpdateForm(contactId) {
    this.deleteFormTargets.find(el => el.dataset.id === contactId).hidden = false
    this.updateFormTargets.find(el => el.dataset.id === contactId).hidden = true
    this.state.displayUpdateForm = 0
  }

  handleClickOnUpdate(event) {
    this.handleDisplayUpdateForm(event.target.dataset.id)
  }

  // Close contact form
  handleClickOnClose(event) {
    if(this.state.displayAddForm) {
      this.handleHideAddForm()
    } else if(this.state.displayUpdateForm !== 0) {
      this.handleHideUpdateForm(event.target.dataset.id)
    } else {
      return
    }
  }

  // Delete contacts
  handleDisplayDeleteForm() {
    this.buttonDeleteTarget.classList.add('active')
    this.closeForms()
    this.state.displayDeleteForm = true
    // Display delete form
    this.deleteFormTargets.forEach((el) => el.classList.add('delete'))
    this.clickContainerTargets.forEach((el) => {
      el.classList.remove('d-none')
      el.innerHTML = `<i class="fas fa-circle click-btn" data-id='${el.dataset.id}'></i>`
    })
    this.counterContainerTarget.classList.remove('d-none')
  }

  handleHideDeleteForm() {
    this.buttonDeleteTarget.classList.remove('active')
    this.state.displayDeleteForm = false
    this.state.contactsToDelete = []
    this.deleteFormTargets.forEach((el) => el.classList.remove('delete'))
    this.deleteFormTargets.forEach(el => el.classList.remove('selected'))
    this.counterContainerTarget.classList.add('d-none')
    this.clickContainerTargets.forEach((el) => {
      el.classList.add('d-none')
      el.innerHTML = `<i class="fas fa-circle click-btn" data-id='${el.dataset.id}'></i>`
    })
    this.counterTarget.innerText = 0
  }

  handleClickOnDelete() {
    this.state.displayDeleteForm ? this.handleHideDeleteForm() : this.handleDisplayDeleteForm()
  }

  handleClickOnSelect(event) {
    this.state.contactsToDelete.includes(event.target.dataset.id) ?
      this.handleUnselect(event.target.dataset.id) : this.handleSelect(event.target.dataset.id)
  }

  handleSelect(contactId) {
    this.state.contactsToDelete.push(contactId)
    this.clickContainerTargets.find(el => el.dataset.id === contactId).innerHTML = `<i class="fas fa-check-circle click-btn" data-id='${contactId}'></i>`
    this.deleteFormTargets.find(el => el.dataset.id === contactId).classList.add('selected')
    this.counterTarget.innerText = this.state.contactsToDelete.length
  }

  handleUnselect(contactId) {
    this.state.contactsToDelete = this.state.contactsToDelete.filter((id) => id !== contactId)
    this.clickContainerTargets.find(el => el.dataset.id === contactId).innerHTML = `<i class="fas fa-circle click-btn" data-id='${contactId}'></i>`
    this.deleteFormTargets.find(el => el.dataset.id === contactId).classList.remove('selected')
    this.counterTarget.innerText = this.state.contactsToDelete.length
  }

  handleClickOnConfirm() {
    fetch('/contacts/destroy_all', {
      method: 'POST',
      headers: { 'Accept': "application/json", 'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken() },
      body: JSON.stringify({contact: { ids: this.state.contactsToDelete }})
    })
      .then(response => response.json())
      .then(data => {
        const deletedContacts = this.deleteFormTargets.filter(contact => {
          return data.includes(parseInt(contact.dataset.id, 10))
        })
        deletedContacts && deletedContacts.forEach(contact => contact.remove())
        this.handleHideDeleteForm()
      })
  }
}
