import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs"

export default class extends Controller {
  static targets = ['subject', 'email', 'content']

  send() {
    fetch(`/contents/${this.contentTarget.dataset.id}/campaigns/email_test`, {
      method: 'POST',
      headers: { 'Accept': "application/json, text/html", 'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken() },
      body: JSON.stringify({
        subject: this.subjectTarget.value,
        email: this.emailTarget.innerHTML
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.flash) {
          document.body.insertAdjacentHTML("beforeend", data.flash)
        }
      })
  }
}
