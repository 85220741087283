import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'uploadPhotoInput',
    'uploadBannerInput',
    'tags',
    'checkBoxes',
    'copyLinks',
    'copyNotice',
    'sharePopover'
  ]

  uploadBanner() {
    this.uploadBannerInputTarget.click()
  }

  uploadPhoto() {
    this.uploadPhotoInputTarget.click()
  }

  clickOnTag(event) {
    const tag = event.target
    if(tag) {
      tag.classList.toggle('btn-tag')
      tag.classList.toggle('btn-purple')
    }
    const checkBox = this.checkBoxesTargets.find(cb => cb.dataset.id === tag.dataset.id)
    if(checkBox) {
      checkBox.click()
    }
  }

  clickOnCopy(event) {
    const content = event.target
    if(content) {
      navigator.clipboard.writeText(content.dataset.url)
      this.copyNoticeTarget.hidden = false
    }
  }

  clickOnShare(event) {
    const popover = this.sharePopoverTargets.find((el) => el.dataset.contentId === event.target.dataset.contentId)
    if(popover) {
      popover.hidden === true ? popover.hidden = false : popover.hidden = true
    }
  }
}
