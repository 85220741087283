import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'arrowUpContacts',
    'arrowDownContacts',
    'arrowUpLists',
    'arrowDownLists',
    'buttonOpen',
    'buttonClose',
    'contactsItem',
    'container',
    'listsItem',
    'menu'
  ]

  toggle() {
    this.containerTarget.classList.toggle('active')
    this.buttonOpenTarget.classList.toggle('d-none')
    this.buttonCloseTarget.classList.toggle('d-none')
    this.menuTarget.classList.toggle('d-none')
  }

  toggleContacts() {
    this.arrowUpContactsTarget.classList.toggle('d-none')
    this.arrowDownContactsTarget.classList.toggle('d-none')
    this.contactsItemTarget.classList.toggle('d-none')
  }

  toggleLists() {
    this.arrowUpListsTarget.classList.toggle('d-none')
    this.arrowDownListsTarget.classList.toggle('d-none')
    this.listsItemTarget.classList.toggle('d-none')
  }
}
