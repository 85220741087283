import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['dateInput', 'nowCheckbox', 'laterCheckbox']

  choose(event) {
    this.nowCheckboxTarget.checked = false
    this.laterCheckboxTarget.checked = false
    event.target.checked = true
    if(event.target.name == 'later') {
      this.dateInputTarget.hidden = false
    } else {
      this.dateInputTarget.hidden = true
    }
  }
}
