import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'uploadPhotoInput'
  ]

  uploadPhoto() {
    this.uploadPhotoInputTarget.click()
  }
}

