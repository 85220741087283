import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ['items', 'form', 'newLists']

  call(event) {
    event.preventDefault()

    fetch(this.formTarget.action, {
      method: 'POST',
      headers: { 'Accept': "application/json", 'X-CSRF-Token': csrfToken() },
      body: new FormData(this.formTarget)
    })
      .then(response => response.json())
      .then((data) => {
        if (data.flash) {
          document.body.insertAdjacentHTML("beforeend", data.flash)
        }
        if (data.inserted_item) {
          this.newListsTarget.insertAdjacentHTML('beforeend', data.inserted_item)
        }
        this.formTarget.outerHTML = data.form
      })
  }
}
