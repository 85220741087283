import Trix from 'trix';
import Rails from "@rails/ujs";

Trix.config.toolbar.getDefaultHTML = toolbarDefaultHTML;

document.addEventListener('trix-initialize', updateToolbars, { once: true });

function updateToolbars(event) {
    const toolbars = document.querySelectorAll('trix-toolbar');
    const html = Trix.config.toolbar.getDefaultHTML();
    toolbars.forEach((toolbar) => (toolbar.innerHTML = html));
}

function toolbarDefaultHTML() {
    return `<div class="trix-button-row">
    <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
      <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="Bold" tabindex="-1">Bold</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="Italic" tabindex="-1">Italic</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="Strikethrough" tabindex="-1">Strikethrough</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="Link" tabindex="-1">Link</button>
    </span>

    <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
      <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading1" title="Heading" tabindex="-1">Heading</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="Quote" tabindex="-1">Quote</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-code" data-trix-attribute="code" title="Code" tabindex="-1">Code</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="Bullets" tabindex="-1">Bullets</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="Numbers" tabindex="-1">Numbers</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="Decrease Level" tabindex="-1" disabled="">Decrease Level</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="Increase Level" tabindex="-1" disabled="">Increase Level</button>
    </span>

    <span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">
      <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="Attach Files" tabindex="-1">Attach Files</button>
    </span>

    <span class="trix-button-group-spacer"></span>

    <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
      <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="Undo" tabindex="-1" disabled="">Undo</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="Redo" tabindex="-1" disabled="">Redo</button>
    </span>
  </div>

  <div class="trix-dialogs" data-trix-dialogs="">
    <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
      <div class="trix-dialog__link-fields">
        <input style="background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.7);
border-radius: 9px;" type="url" name="href" class="trix-input trix-input--dialog" placeholder="Enter a URL…" aria-label="URL" required="" data-trix-input="" disabled="disabled">
        <div style="border: 0" class="trix-button-group">
          <input type="button" class="trix-button trix-button--dialog" style="color: white;
height: 31px;
width: 68px;

background: #000000;
border-radius: 8px;
color: white
" value="Link" data-trix-method="setAttribute">
          <input type="button" class="trix-button trix-button--dialog"
           style="width: 68px;
height: 31px;

background: #F8F4F4;
border: 1px solid #000000;
border-radius: 8px;
margin-left: 10px;
"
           value="Unlink" data-trix-method="removeAttribute">
        </div>
      </div>
      <div data-behavior="embed_container" style="margin-top: 20px">
        <div class="link_to_embed link_to_embed--new">
          Would you like to embed media from this site?
          <input class="btn btn-tertiary outline btn-small ml-3" type="button" data-behavior="embed_url" style="
color: white;
background: #5B5028;
width: 122px;
padding-left: 5px;
padding-right: 5px;
border-radius: 8px;" value="Yes, embed it!">
        </div>
      </div>
    </div>
  </div>`
}


class EmbedController {
    constructor(element) {
        this.pattern = /^https:\/\/([^\.]+\.)?youtube\.com\/watch\?v=(.*)/

        this.element = element
        this.editor = element.editor
        this.toolbar = element.toolbarElement
        this.hrefElement = this.toolbar.querySelector("[data-trix-input][name='href']")
        this.embedContainerElement = this.toolbar.querySelector("[data-behavior='embed_container']")
        this.embedElement = this.toolbar.querySelector("[data-behavior='embed_url']")

        this.reset()
        this.installEventHandlers()
    }

    installEventHandlers() {
        this.hrefElement.addEventListener("input", this.didInput.bind(this))
        this.hrefElement.addEventListener("focusin", this.didInput.bind(this))
        this.embedElement.addEventListener("click", this.embed.bind(this))
    }

    didInput(event) {
        let value = event.target.value.trim()
        let matches = value.match(this.pattern)
        console.log(value, matches)

        // When patterns are loaded, we can just fetch the embed code
        if (matches != null) {
            this.fetch(matches[2])

            // No embed code, just reset the form
        } else {
            this.reset()
        }
    }

    fetch(value) {
        Rails.ajax({
            url: `/youtube/${encodeURIComponent(value)}`,
            type: 'get',
            error: this.reset.bind(this),
            success: this.showEmbed.bind(this)
        })
    }

    embed(event) {
        if (this.currentEmbed == null) { return }

        let attachment = new Trix.Attachment(this.currentEmbed)
        this.editor.insertAttachment(attachment)
        this.element.focus()
    }

    showEmbed(embed) {
        this.currentEmbed = embed
        this.embedContainerElement.style.display = "block"
    }

    reset() {
        this.embedContainerElement.style.display = "none"
        this.currentEmbed = null
    }
}

document.addEventListener("trix-initialize", function(event) {
    new EmbedController(event.target)
})