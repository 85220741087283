import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs"

export default class extends Controller {
  static targets = [
    'footer',
    'counter',
    'checkbox',
    'list',
    'closeModal',
    'openBtn',
    'filters'
  ]

  initialize() {
    this.countrySelect()
    this.state = {
      contacts: [],
      displayFooter: false,
    }
  }

  choose(event) {
    if(this.state.contacts.map(contact => contact.dataset.email).includes(event.target.dataset.email)) {
      this.state.contacts = this.state.contacts.filter(contact => contact.dataset.email !== event.target.dataset.email)
      this.counterTarget.innerText = this.state.contacts.length
    } else {
      this.state.contacts.push(event.target)
      this.counterTarget.innerText = this.state.contacts.length
    }
    if(this.state.contacts.length > 0) {
      this.footerTarget.classList.remove('d-none')
    } else {
      this.footerTarget.classList.add('d-none')
    }
  }

  selectAll() {
    if(this.state.contacts.length > 0) {
      this.state.contacts = []
      this.checkboxTargets.forEach((cb) => cb.checked = false)
      this.counterTarget.innerText = this.state.contacts.length
    } else {
      this.state.contacts = this.checkboxTargets
      this.checkboxTargets.forEach((cb) => cb.checked = true)
      this.counterTarget.innerText = this.state.contacts.length
    }
    if(this.state.contacts.length > 0) {
      this.footerTarget.classList.remove('d-none')
    } else {
      this.footerTarget.classList.add('d-none')
    }
  }

  save() {
    const body = {
      contacts: this.state.contacts.map((contact) => ({
                                                            first_name: contact.dataset.firstName,
                                                            last_name: contact.dataset.lastName,
                                                            email: contact.dataset.email,
                                                            function: contact.dataset.function,
                                                            media: contact.dataset.media,
                                                            address: contact.dataset.address,
                                                          })),
      list_id: this.listTargets.find(list => list.selected === true).value
    }

    fetch('/agility_lists/save_contacts_in_list', {
      method: 'POST',
      headers: { 'Accept': "application/json", 'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken() },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(data => {
        if (data.flash) {
          document.body.insertAdjacentHTML("beforeend", data.flash)
          this.closeModalTarget.click()
        }
      })
  }

  openFilters() {
    this.openBtnTarget.classList.toggle('fa-angle-down')
    this.openBtnTarget.classList.toggle('fa-angle-up')
    this.filtersTarget.classList.toggle('d-none')
  }

  countrySelect() {
    if(window.location.href.includes('search_by_keyword')) return;

    if (document.getElementById('query_country').value == 375) // if country is france
      document.getElementById('city-list').style.display = 'block'
    else
      document.getElementById('city-list').style.display = 'none'
  }
}
